// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.general-cont-calendar {
  height: 100vh;
  margin: 2rem;
}
.general-cont-calendar .header {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
.general-cont-calendar .header h1 {
  font-size: clamp(1.5rem, 5vw, 3rem);
  font-weight: bold;
}

@media (max-width: 800px) {
  .general-cont-calendar {
    margin: 10px;
    margin-bottom: 2rem;
  }
  .general-cont-calendar .header h1 {
    font-size: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/calendar/calendarPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;AACJ;AACI;EACI,cAAA;EACA,kBAAA;EACA,WAAA;AACR;AAAQ;EACI,mCAAA;EACA,iBAAA;AAEZ;;AAIA;EACI;IACI,YAAA;IACA,mBAAA;EADN;EAGU;IACI,eAAA;EADd;AACF","sourcesContent":[".general-cont-calendar{\n    height: 100vh;\n    margin: 2rem;\n\n    .header{\n        margin: 0 auto;\n        text-align: center;\n        width: 100%;\n        h1{\n            font-size: clamp(1.5rem, 5vw, 3rem);\n            font-weight: bold;\n        }\n\n    }\n}\n\n@media (max-width: 800px) {\n    .general-cont-calendar{\n        margin: 10px;\n        margin-bottom: 2rem;\n        .header{\n            h1{\n                font-size: 2rem;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
