// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-picker-cont {
  display: flex;
  gap: 10px;
  align-items: center;
}
.time-picker-cont input {
  border: none;
  background-color: rgb(244, 244, 244);
  padding: 5px;
  font-size: 30px;
  width: 45px !important;
  min-width: auto;
  text-align: center;
}
.time-picker-cont input::-webkit-outer-spin-button,
.time-picker-cont input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}
.time-picker-cont input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}
.time-picker-cont .semi-colon {
  border: none !important;
  font-size: 2rem !important;
}`, "",{"version":3,"sources":["webpack://./src/components/timePicker/timePicker.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;AACJ;AACI;EACI,YAAA;EACA,oCAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;AACR;AAGI;;EAEI,8CAAA;EACA,wBAAA;EACA,SAAA;EACA,uEAAA;AADR;AAII;EACI,0BAAA;EACA,YAAA;AAFR;AAKI;EACI,uBAAA;EACA,0BAAA;AAHR","sourcesContent":[".time-picker-cont {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n\n    input {\n        border: none;\n        background-color: rgb(244 244 244);\n        padding: 5px;\n        font-size: 30px;\n        width: 45px!important;\n        min-width: auto;\n        text-align: center;\n\n    }\n\n    input::-webkit-outer-spin-button,\n    input::-webkit-inner-spin-button {\n        /* display: none; <- Crashes Chrome on hover */\n        -webkit-appearance: none;\n        margin: 0;\n        /* <-- Apparently some margin are still there even though it's hidden */\n    }\n\n    input[type=number] {\n        -moz-appearance: textfield;\n        /* Firefox */\n    }\n\n    .semi-colon {\n        border: none !important;\n        font-size: 2rem!important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
