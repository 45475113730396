import React from 'react';
import './footer.scss';
function Footer(){

return (
    <>
        <footer className="footer">
            <div className="container">
                <p>Copyright © 2023. All Rights Reserved.</p>
            </div>
        </footer>
    </>
);

}

export default Footer;