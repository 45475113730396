// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
  .rbc-time-content > .rbc-time-gutter {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/calendar/calendar.scss"],"names":[],"mappings":"AACA;EAQI;IACI,aAAA;EAPN;AACF","sourcesContent":["\n@media (max-width: 768px) {\n    \n    // .rbc-time-content > .rbc-time-gutter{\n    //     display: none;\n    // }\n    // .rbc-time-content{\n    //     flex-direction: column;\n    // }\n    .rbc-time-content > .rbc-time-gutter{\n        display: none;\n    }\n\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
