// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: var(--secondary-color);
  color: var(--main-color);
  padding: 1rem;
  width: 100%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.scss"],"names":[],"mappings":"AAEA;EACI,wCAAA;EACA,wBAAA;EACA,aAAA;EAEA,WAAA;EACA,kBAAA;AAFJ","sourcesContent":["\n\n.footer{\n    background-color: var(--secondary-color);\n    color: var(--main-color);\n    padding: 1rem;\n    // margin-top: 10rem;\n    width: 100%;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
